module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Transform My Wealth","short_name":"TMB","start_url":"/","background_color":"#0E0417","theme_color":"#0E0417","display":"standalone","icon":"src/img/favicon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"554eb50551e307db200aaae0a2608e83"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
